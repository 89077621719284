import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { DC_JOIN, drill } from "../../codebricks-runtime/CBUtil";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import { cb_popout } from "../controls/cb_popout";
import { CBWebUtil } from "../controls/cb_web_util";

export class cd_autocomplete_webcomponent extends HTMLElement {
    ci: web_cd_autocomplete | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
            let idx = this.getAttribute('idx') as string;
            let container_id = this.getAttribute('container_id') as string;
            let nsid = this.getAttribute('nsid') as string;
            this.ci = new web_cd_autocomplete(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('cd-autocomplete', cd_autocomplete_webcomponent);

export class web_cd_autocomplete extends CodeBrick {

    element: HTMLElement;
    initialised = false;
    value = "";
    options = [];
    cfg = null as any;
    value_path = "";
    label_path = "";
    group_path = "";
    match_condition = "contains";
    search = null as string | null;
    disabled = false;

    popout = null as cb_popout | null;

    set_value = false;

    selected_idx = 0;
    num_items = 0;

    last_emitted_search = null as string | null;

    snapshot = { cfg: {} } as any;

    search_id = 0;

    cements = {} as { [child_idx: number]: any };

    //shadowroot: ShadowRoot;
    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;
        //this.shadowroot = this.attachShadow({ mode: 'open' });

        if(!(<any>window).c_autocomplete_callback) {
            (<any>window).c_autocomplete_callback = function(event: any, ac_id: string, idx: number) {
                event.stopPropagation();
                let ac = (<any>window).codebricks_context.bricks[ac_id];

                let option = ac.options[idx];

                ac.selectOption(option);
                ac.popout.closeSelect();
            }
        }

        if(!(<any>window).c_autocomplete_x) {
            (<any>window).c_autocomplete_x = function(ac_id: string) {
                let ac = (<any>window).codebricks_context.bricks[ac_id];
                ac.clear();
            }
        }
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<any> {
        //console.log("web_c_autocomplete "+this.blueprint.name+" cb_event "+input+" "+JSON.stringify(cfg));

        //console.log(info.source+"---"+this.blueprint.name + "."+input+" "+JSON.stringify(cfg));

        if (input == 'cfg') {

            this.cfg = cfg;

            if (!this.initialised) {

                let id = this.brick_id;

                let style = "";
                if(cfg.style) {
                    for(let v in cfg.style) {
                        style += v+':'+cfg.style[v]+";"
                    }
                    style = ` style="${style}"`;
                }

                this.element.innerHTML =
                    `<div ${CBWebUtil.GetElementStylesString("form-element-container c-autocomplete-container", cfg, "container")} id="${this.brick_id}$container">
                    <label ${CBWebUtil.GetElementStylesString(`form-element-label c-autocomplete-label${cfg.label ? "" : " hidden"}`, cfg, "label")} for="${this.brick_id}">${cfg.label || ''}</label>
                    <div ${CBWebUtil.GetElementStylesString("form-element c-autocomplete-input no_click_close", cfg, "input")} id="${this.brick_id}$select" contenteditable="true" placeholder="${cfg.placeholder}"></div>
                    <div ${CBWebUtil.GetElementStylesString("c-autocomplete-x hidden", cfg, "x")} onclick="c_autocomplete_x('${this.brick_id}')"></div>
                </div><div class="c-input-validation-message" id="${this.brick_id}$validation" style="display:none"></div><div id="${this.brick_id}_loader" class="loader"></div>`;

                //let self = this;
                this.popout = new cb_popout(id, true, cfg, "autocomplete", true, true,
                function() {
                    //pop opened
                    //console.log("pop opened");
                    if(self.search && self.search != self.last_emitted_search) {
                        self.last_emitted_search = self.search;
                        self.cb_emit({"@search": self.search });
                    }
                },
                function() {
                    //console.log("pop closed");
                });
                this.popout.setDropHtml("<span class='light-text'>Start typing...</span>");

                this.popout.init(true);

                this.popout.setLabel(cfg.label);

                this.initialised = true;
                let input_element = document.getElementById(`${this.brick_id}$select`) as HTMLInputElement;

                let self = this;

                //self.popout.setDropHtml("<p>hello</p><p>hello</p><p>hello</p><p>hello</p><p>hello</p><p>hello</p><p>hello</p><p>hello</p>");

                input_element.addEventListener('input', function() {

                    self.search = this.innerText;//this.value;

                    //self.popout.setDropHtml(this.value);

                    // if(self.popout) {
                    //     self.popout.openPopout(self.popout);
                    // }
                    let debounce_ms = self.cfg.debounce_ms;
                    if((debounce_ms !== 0 && !debounce_ms) || isNaN(debounce_ms)) {
                        debounce_ms = 600; //default if not set
                    }

                    self.show_x(self.search != "");

                    if(self.search != self.last_emitted_search) {
                        self.last_emitted_search = self.search;
                        self.search_id++;
                        let this_search_id = self.search_id;
                        setTimeout(function() {
                            if(self.search_id == this_search_id) {
                                self.cb_emit({"@search": self.search });
                            }
                        }, debounce_ms);
                    }
                });

                input_element.addEventListener("keydown", function (e: any) {
                    
                    let items_container = document.getElementById(self.brick_id + "$items");
                    if (e.keyCode == 40 || e.keyCode == 38) {
                        e.preventDefault();
                        if(e.keyCode == 40) {
                            if(self.selected_idx >= self.num_items - 1) {
                                self.selected_idx = self.num_items - 1;
                                return;
                            }
                            /*If the arrow DOWN key is pressed,
                            increase the currentFocus variable:*/
                            self.selected_idx++;

                            if(items_container) {
                                let items = items_container.querySelectorAll(".c-select-item");
                                let new_sel = items[self.selected_idx];
                                if(!new_sel) {
                                    self.selected_idx++;
                                }
                            }
                        }
                        if(e.keyCode == 38) {
                            if(self.selected_idx <= 0) {
                                self.selected_idx = 0;
                                return;
                            }
                            /*If the arrow UP key is pressed,
                            increase the currentFocus variable:*/
                            self.selected_idx--;

                            if(items_container) {
                                let items = items_container.querySelectorAll(".c-select-item");
                                let new_sel = items[self.selected_idx];
                                if(!new_sel) {
                                    self.selected_idx--;
                                }
                            }
                        }
                        /*and and make the current item more visible:*/
                        if(items_container) {
                            let sel = items_container.querySelector(".c-select-item-selected");
                            if(sel) {
                                sel.classList.remove("c-select-item-selected");
                            }
                            let items = items_container.querySelectorAll(".c-select-item");
                            if(items) {
                                let new_sel = items[self.selected_idx];//items_container.children[self.selected_idx];
                                if(new_sel) {
                                    new_sel.classList.add("c-select-item-selected");
                                    new_sel.scrollIntoView();
                                }
                                
                            }
                        }
                    } else if (e.keyCode == 13) {
                        e.preventDefault();

                        if(self.popout && self.popout.isOpen()) {
                            /*If the ENTER key is pressed*/
                            self.selectOption(self.options[self.selected_idx]);

                            self.popout.closeSelect();
                        }
                    } else if (e.keyCode == 27) {
                        if(self.popout && self.popout.isOpen()) {
                            /*If the ESCAPE key is pressed*/
                            self.popout.closeSelect();
                        }
                    }
                });

                // this.inp = input_element;
                // input_element.value = this.value;
                this.value_path = cfg.value_path || "";
                this.label_path = cfg.label_path || "";
                this.group_path = cfg.group_path || "";
                this.match_condition = cfg.match_condition || "contains";


                if(cfg.value === undefined || cfg.value === null) {
                    cfg.value = "";
                }
                if(this.value === undefined || this.value === null) {
                    this.value = "";
                }
            }
            else {
                let container = document.getElementById(this.brick_id + "$container");
                if(container && container.children[0]) {
                    container.children[0].innerHTML = cfg.label || '';
                    if(cfg.label) {
                        container.children[0].classList.remove("hidden");
                    }
                    else {
                        container.children[0].classList.add("hidden");
                    }
                }

                if(container) {
                    CBWebUtil.ApplyElementStyles(container, cfg, "container");

                    CBWebUtil.ApplyElementStyles(container.firstElementChild as HTMLElement, cfg, "label");
                    CBWebUtil.ApplyElementStyles(container.children[1] as HTMLElement, cfg, "input");
                }

                

            }

            
            // if(cfg.style_classes) {
            //     let container = document.getElementById(this.brick_id + "$container");
            //     if(container) {
            //         for(let cl in cfg.style_classes) {
            //             if(cfg.style_classes[cl]) {
            //                 container.classList.add(cfg.style_classes[cl]);
            //             }
            //         }
            //     }
            // }

            this.disabled = Boolean(cfg.disabled);
            if(this.popout) {
                this.popout.disabled = this.disabled;
            }
            let input = document.getElementById(this.brick_id+"$select");
            if(input) {
                input.spellcheck = false;
                input.setAttribute("contenteditable", ""+!this.disabled);
                if(this.disabled) {
                    input.classList.add("c-autocomplete-disabled");
                }
            }

            //if(this.value != cfg.value) {
                this.value = cfg.value;

                //let ret = {} as any;
                //this.set_value = true;
                // if(!cfg || !cfg.dont_send_initial) {
                //     ret = this.selectValue(true) as any;
                //     ret = ret || { "@" : this.value } ;
                // }
                //ret["@search"] = cfg.search
                this.setDisplayValue(cfg.value_label);
                
                //Only do search query if user interacts, else we could do way too many queries if ac is in table
                //return { "@" : { value: this.value, label: cfg.value_label }, "@search": cfg.value_label };
                this.search = cfg.value_label;

                //console.log("web_c_autocomplete "+this.blueprint.name+" return "+JSON.stringify({ value: this.value, label: cfg.value_label }));
                
                this.snapshot.cfg.value = cfg.value;
                this.snapshot.cfg.value_label = cfg.value_label;
                return { "@" : { value: this.value, label: cfg.value_label }};
            //}
        }
        else if(input == "data") {

            this.options = cfg || [];
            
            this.refreshSearch(this.search === null ? "" : this.search);

            // if(this.set_value) {
            //     this.set_value = false;
            //     return this.selectValue(true);
            // }     

            if(cfg && this.popout && !this.popout.isOpen()) {
                this.popout.openPopout(this.popout);
            }
        }
        else if(input == "clear") {
            if(cfg) {
                this.clear(false);
            }
        }
        else if(input == "focus") {
            if(cfg) {
                let input = document.getElementById(this.brick_id + "$select") as HTMLInputElement;
                if(input) {
                    input.focus();
                }
            }
        }
    }

    setDisplayValue(label: string) {
        if(label === undefined || label === null) {
            label = "";
        }
        this.show_x(label != "");
        let input = document.getElementById(this.brick_id + "$select") as HTMLInputElement;
        if(input) {
            //input.value = label;
            input.innerHTML = label;
        }
        this.clearValidation();
        this.refreshSearch(label);
    }
    clear(focus = true) {
        this.show_x(false);
        this.refreshSearch("");
        let input = document.getElementById(this.brick_id + "$select") as HTMLInputElement;
        if(input) {
            //input.value = '';

            input.innerHTML = '';
            if(focus) {
                input.focus();
            }
        }
            
        this.value = "";

        delete this.snapshot.cfg.value;
        delete this.snapshot.cfg.value_label;

        this.clearValidation();

        this.cb_emit({ "@" : null, "@option_object" : null, "@search": "", "@user_changed" : null  });
    }
    selectOption(option: any) {
        let value = option;
        let label = option;
        if(this.value_path) {
            value = drill(this.value_path, option);
            label = value;
        }
        if(this.label_path) {
            label = drill(this.label_path, option);
        }

        this.value = value;

        this.setDisplayValue(label);

        this.snapshot.cfg.value = value;
        this.snapshot.cfg.value_label = label;

        if(this.popout) {
            this.popout.closeSelect();//If the autocomplete input wraps because of may elements, the select overlaps it, so we just close it.
        }

        this.cb_emit({ "@" : { value, label }, "@user_changed" : { value, label } });
    }

    async refreshSearch(search: string) {

        if(!this.cfg) {
            return;
        }

        this.search = String(search || "");

        this.selected_idx = -1;
        this.num_items = 0;
        let list_html = `<div class="select-items" id="${this.brick_id}$items">`;

        //console.log("this.options.length "+this.options.length + " this.search "+this.search);

        let lowercase_search = this.search.toLowerCase();

        let i = 0;
        let group = "";
        let items = 0;

        let row_idx = 0;

        let dc_data = [] as any[];
        
        for(let option of this.options) {
            let value = option as any;
            let label = option as any;
            if(this.value_path) {
                value = drill(this.value_path, option);
                label = value;
            }
            if(this.label_path) {
                label = drill(this.label_path, option);
            }
            
            let indexof = (label + "").toLowerCase().indexOf(lowercase_search);

            if(this.search === "" || 
                (this.match_condition == "contains" && indexof != -1) ||
                (this.match_condition == "startswith" && indexof == 0)
            ) {

                dc_data.push(option);

                if(this.group_path) {
                    let this_group = drill(this.group_path, option);
                    if(this_group != group) {
                        list_html += `<div class="c-autocomplete-group">${this_group}</div>`;
                    }
                    group = this_group;
                }

                if(this.selected_idx == -1) {
                    this.selected_idx = i;
                }

                if(this.blueprint.contains && this.blueprint.contains.length > 0) {
                    let dc_root = (this.dc || "") + (DC_JOIN + this.blueprint.name);
                    let child_dc = dc_root + DC_JOIN + row_idx;
                    
                    for(let sub of this.blueprint.contains) {
                        let cement = this.cements[i] || sub.cement;

                        // if(cement && cement.hidden) {
                        //     continue;
                        // }
                        let cement_child_id = this.brick_id+"$"+row_idx+"$"+i;

      
                        let brick = CBWebUtil.BrickHtml(sub, this, i, child_dc);
                        if(cement) { 
                            let hidden = (cement.hidden) ? ` hidden` : "";

                            let prefix = sub.type.split('-')[0];
                            if(prefix.indexOf('i') == -1 && prefix[0] != 's') {
                                list_html += `<div ${CBWebUtil.GetElementStylesString(hidden, cement, "")} id="${cement_child_id}" class="c-select-item${i == this.selected_idx ? " c-select-item-selected" : ""}">${brick}</div>`;
                            }
                            else {
                                list_html += `<div style="position:absolute" class="c-select-item${i == this.selected_idx ? " c-select-item-selected" : ""}">` + brick + `</div>`;
                            }
                        }
                        else {
                            list_html += brick;
                        }
                    }

                    row_idx++;
                }
                else {


                    list_html += `<div onclick="c_autocomplete_callback(event, '${this.brick_id}',${i})" class="c-select-item${i == this.selected_idx ? " c-select-item-selected" : ""}">${label}</div>`;
                }
                this.num_items++;
                
            }

            i++;
        }

        if(this.popout) {
            this.popout.setDropHtml(list_html + "</div>");
        }

        await this.send_dynamic_initialisation_events(dc_data);
    }

    show_x(show: boolean) {
        let container = document.getElementById(this.brick_id + "$container");
        if(container) {
            let x = container.querySelector(".c-autocomplete-x");
            if(x) {
                if(show) {
                    x.classList.remove("hidden");
                }
                else {
                    x.classList.add("hidden");
                }
            }
        }
    }

    cb_initial_cement(cements: { [child_idx: number]: any }) {
        this.cements = cements;
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {

        let cement_child_id = this.brick_id+"$"+row_idx+"$"+child_idx;

        let child = document.getElementById(cement_child_id);
        if(child) {
            if(cement.hidden) {
                child.classList.add("hidden");
            }
            else {
                child.classList.remove("hidden");
            }

            CBWebUtil.ApplyElementStyles(child, cement, "");
        }

    }

    cb_snapshot() {
        return this.snapshot;
    }

    cb_status(status: string): void {
        if (status == "") {
            let input = document.getElementById(`${this.brick_id}$select`);
            if (input) {
                (<HTMLInputElement>input).classList.remove("required");
            }
            let items = document.getElementById(`${this.brick_id}$items`);
            if (items) {
                (<HTMLElement>items).classList.remove("loading");
            }
        }
        else if(status == "loading") {
            let items = document.getElementById(`${this.brick_id}$items`);
            if (items) {
                items.innerHTML = "";
                (<HTMLElement>items).classList.add("loading");
            }
        }
    }

    cb_validate(): boolean {
        let validates = true;
        let validation_message = "";
        if(this.cfg.validation) {
            let select = document.getElementById(`${this.brick_id}$select`) as HTMLInputElement;
            if(select) {
                let value = this.value;
                
                if(this.cfg.validation.required && (value === "" || value === null || value === undefined)) {
                    validates = false;
                    validation_message = (this.cfg.label || this.cfg.placeholder || "Value") + " is a required field";
                }
            }
        }
        if(validates) {
            this.clearValidation();
        }
        else {
            let validation_message_container = document.getElementById(`${this.brick_id}$validation`);

            let select = document.getElementById(`${this.brick_id}$select`) as HTMLInputElement;

            if(select) {
                select.classList.add("c-input-validation-failed");
            }

            if(validation_message_container) {
                validation_message_container.innerHTML = validation_message;
                validation_message_container.style.display = "block";
            }
        }
        return validates;
    }

    clearValidation() {
        let input_wrap = document.getElementById(`${this.brick_id}$wrap`) as HTMLInputElement;
        if(input_wrap) {
            input_wrap.classList.remove("c-input-validation-failed");
        }
        let validation_message_container = document.getElementById(`${this.brick_id}$validation`);
        if(validation_message_container) {
            validation_message_container.style.display = "none";
            validation_message_container.innerHTML = "";
        }
    }
}