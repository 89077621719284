import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import { cb_tooltip } from "../controls/cb_tooltip";
import { CBWebUtil } from "../controls/cb_web_util";

export class c_radio_webcomponent extends HTMLElement {
    ci: web_c_radio | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
            let idx = this.getAttribute('idx') as string;
            let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_c_radio(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('c-radio', c_radio_webcomponent);

export class web_c_radio extends CodeBrick {

    element: HTMLElement;
    style_classes = [] as string[];
    initialised = false;
    cfg: any;

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<any> {
        //console.log(this.blueprint.name+" cb_event "+input+" "+JSON.stringify(cfg));
        
        return this.setDisplay(cfg);
        
    }

    setDisplay(cfg: any) {

        this.cfg = cfg;
        if(!this.initialised) {

            let tooltip = "";
            if(cfg.tooltip && cfg.tooltip.text) {
                tooltip = ` tooltip="${cfg.tooltip.text}"`;
            }

            let checked = "";
            if(cfg.selected) {
                checked = `checked="checked"`
            }

            this.element.innerHTML = `<input type="radio" name="${cfg.group_name}" id="${this.brick_id}" value="${(cfg.value || cfg.value === 0) ? cfg.value : cfg.label}" class="c-radio" ${tooltip} ${checked}></input><label for="${this.brick_id}">${cfg.label}</label>`;

            let self = this;
            let btn = document.getElementById(`${this.brick_id}`);
            if(btn) {
                btn.addEventListener('change', async function(e: any) {

                    if((<any>window).cb_popout_global && (<any>window).cb_popout_global.window_click_handler) {
                        //Close any open selects so they can send their events before processing this button's event, in case the selection is used in this event.
                        (<any>window).cb_popout_global.window_click_handler(e);
                    }

                    //console.log(self.blueprint.name+' emit '+JSON.stringify(self.outdata));

                    await self.cb_emit({"@":(self.cfg.value || self.cfg.value === 0) ? self.cfg.value : self.cfg.label});
                    
                });

                if(cfg.tooltip && cfg.tooltip.text) {
                    cb_tooltip.set_tooltip(btn);
                }
            }
            this.initialised = true;
        }


        let btn = document.getElementById(`${this.brick_id}`) as HTMLButtonElement;
        if(btn) {
            if(cfg.style) {
                for(let v in cfg.style) {
                    //(btn.style as any)[v] = cfg.style[v];
                    btn.style.setProperty(v, cfg.style[v]);
                }
            }

            if(cfg.tooltip && cfg.tooltip.text) {
                btn.setAttribute("tooltip", cfg.tooltip.text);
                cb_tooltip.set_tooltip(btn);
            }

            CBWebUtil.ApplyElementStyles(btn, cfg, "button");

            if(cfg.nativeoptions && cfg.nativeoptions.html) {
                if(cfg.nativeoptions.html.attributes) {
                    for(let attribute in cfg.nativeoptions.html.attributes) {
                        btn.setAttribute(attribute, cfg.nativeoptions.html.attributes[attribute]);
                    }
                }
            }

            if(cfg.hidden) {
                btn.classList.add("hidden");
            }
            else {
                btn.classList.remove("hidden");
            }


            if(cfg.disabled) {
                btn.disabled = true;
            }
            else {
                btn.disabled = false;
            }

            if(cfg.selected) {
                return {"@":(cfg.value || cfg.value === 0) ? cfg.value : cfg.label};
            }

        }
    }

    cb_initial_cement(cements: { [child_idx: number]: any }) {
    }

    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
    }

    async cb_status(status: string): Promise<void> {

        //console.log("c-button "+this.blueprint.name+" cb_status "+status);

        let button = document.getElementById(this.brick_id) as HTMLButtonElement;
        if(status == "loading" && !this.no_loader) {
            if(button) {
                //button.classList.add("loading");
                button.classList.add("loader");
                button.classList.add("btn-loader-loading");
                button.disabled = true;
            }
        }
        else {
            if(button) {
                //button.classList.remove("loading");
                button.classList.remove("btn-loader-loading");
                button.classList.remove("loader");
                button.disabled = false
                
            }
        }
    }

    cb_trigger_submit() {
        //this.cb_emit({"@":this.outdata});
        let btn = document.getElementById(`${this.brick_id}`);
        if(btn) {
            btn.click();
        }
    }

    cb_snapshot() {}
   
}